<template>
  <div>
    <!-- 固定筛选项 -->
    <div class="dynamicFiltering">
      <div class="row">
        <div class="sortingOptions" style="margin-right:auto">
          <div class="switchBox dipflex flexacenter" style="margin-left:auto">
            <img :src="require('@/assets/allnewFile/cicon.svg')" alt="" />
            <img :src="require('@/assets/allnewFile/eicon.svg')" alt="" />
            <img
              :src="require('@/assets/allnewFile/maohao.svg')"
              alt=""
              style="margin-right:0"
            />
            <el-switch
              v-model="valueConTrol.ce"
              active-color="#1767D0"
              @change="confirmChuanshen"
              :active-text="$fanyi('与证书')"
              :inactive-text="$fanyi('查看')"
            >
            </el-switch>
          </div>
        </div>
        <div
          class="sortingOptions"
          :class="{ active: zonghe }"
          @click="setSortValue()"
        >
          <span>Clasificación general</span>
        </div>
        <div
          class="sortingOptions"
          :class="{ active: haveSort('monthSold') }"
          @click="setSortValue('monthSold')"
        >
          <span>{{ $fanyi("每月销售") }}</span>
          <div class="sortIcon " :class="{ down: haveSort('monthSold') }"></div>
        </div>
        <div
          class="sortingOptions"
          :class="{ active: haveSort('rePurchaseRate') }"
          @click="setSortValue('rePurchaseRate')"
        >
          <span>{{ $fanyi("回购率") }}</span>
          <div
            class="sortIcon "
            :class="{ down: haveSort('rePurchaseRate') }"
          ></div>
        </div>
        <div
          class="sortingOptions"
          :class="{ active: haveSort('price') }"
          @click="setSortValue('price')"
        >
          <span>{{ $fanyi("总价") }}</span>

          <div
            class="sortIcon"
            :class="{ up: priceSort() == 'desc', down: priceSort() == 'asc' }"
          ></div>
        </div>
        <div class="searchBtn">
          <el-button class="fontBlueBtn" @click="clearChiose">{{
            $fanyi("清理")
          }}</el-button>
        </div>

        <!-- <div class="searchInputBox" style="margin-left:auto">
          <div class="flex acenter jcenter searchIcon">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6684f8640e988.svg"
              alt=""
            />
          </div>
          <el-input
            :placeholder="$fanyi('产品名称')"
            v-model="valueConTrol.keySearch"
            clearable
          >
          </el-input>
        </div>
        <div class="searchBtn">
          <el-button
            type="primary"
            class="bgBlueBtn"
            @click="confirmChuanshen"
            >{{ $fanyi("搜索") }}</el-button
          >
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      valueConTrol: {
        yanXuanShangPin1688: false, //1688严选商品
        yanXuanGongChang1688: false, //1688严选工厂
        dianPuPinFen: "", //店铺评分
        qiTianWuLiYou: false, //七天无理由
        yiJinaQiFa: false, //一件起发
        sort: "", //排序字符串
        sortObj: [],
        priceStart: "", //起始价格
        priceEnd: "", //结束价格
        snId: {}, //动态搜索项
        keySearch: "", //商品搜索
        ce: true, //ce筛选
      },
      zonghe: false,
      options: [
        {
          value: "",
          label: "Todo",
        },
        {
          value: "totalEpScoreLv1",
          label: "5★",
        },
        {
          value: "totalEpScoreLv2",
          label: "4.5★~5.0★",
        },
        {
          value: "totalEpScoreLv3",
          label: "4.0★~4.5★",
        },
      ],
      keySearchList: [],
    };
  },
  components: {},
  computed: {},
  created() {},
  methods: {
    // 清理选项
    clearChiose() {
      this.valueConTrol = {
        yanXuanShangPin1688: false, //1688严选商品
        yanXuanGongChang1688: false, //1688严选工厂
        dianPuPinFen: "", //店铺评分
        qiTianWuLiYou: false, //七天无理由
        yiJinaQiFa: false, //一件起发
        sort: "", //排序字符串
        sortObj: [],
        priceStart: "", //起始价格
        priceEnd: "", //结束价格
        snId: {}, //动态搜索项
        keySearch: "", //商品搜索
        ce: true, //ce筛选
      };
      this.confirmChuanshen();
    },
    // 图标展示判断
    priceSort() {
      if (this.valueConTrol.sort.indexOf("price") != -1) {
        if (this.valueConTrol.sort.indexOf("desc") != -1) {
          return "desc";
        } else {
          return "asc";
        }
      } else {
        return "";
      }
    },

    // 设置sort参数
    setSortValue(name) {
      if (!name) {
        this.valueConTrol.sort = "";
        this.zonghe = true;
      } else if (name == "price") {
        this.zonghe = false;
        if (this.valueConTrol.sort.indexOf("desc") != -1) {
          this.valueConTrol.sort = `{${name}:'asc'}`;
          this.valueConTrol.sortObj = [
            {
              key: name,
              value: "asc",
            },
          ];
        } else {
          this.valueConTrol.sort = `{${name}:'desc'}`;
          this.valueConTrol.sortObj = [
            {
              key: name,
              value: "desc",
            },
          ];
        }
      } else {
        this.zonghe = false;
        this.valueConTrol.sort = `{${name}:'desc'}`;
        this.valueConTrol.sortObj = [
          {
            key: name,
            value: "asc",
          },
        ];
      }
      this.confirmChuanshen();
      this.$forceUpdate();
    },

    // 判断sort是否有已选
    haveSort(name) {
      if (this.valueConTrol.sort.indexOf(name) != -1) {
        return true;
      } else {
        return false;
      }
    },

    // 拼凑传参
    confirmChuanshen() {
      let searchData = {};
      if (this.$route.query.type == "imgSearch") {
        // console.log(JSON.parse(this.valueConTrol.sort));

        searchData.order_by = this.valueConTrol.sortObj;
        searchData.priceMin = this.valueConTrol.priceStart;
        searchData.priceMax = this.valueConTrol.priceEnd;

        let filter = [];
        if (this.valueConTrol.yanXuanShangPin1688) {
          filter.push("jxhy");
        }
        if (this.valueConTrol.yanXuanGongChang1688) {
          filter.push("certifiedFactory");
        }
        if (this.valueConTrol.dianPuPinFen) {
          filter.push(this.valueConTrol.dianPuPinFen);
        }
        if (this.valueConTrol.qiTianWuLiYou) {
          filter.push("noReason7DReturn");
        }
        if (this.valueConTrol.yiJinaQiFa) {
          filter.push("isOnePsale");
        }
      } else {
        let filter = [];
        if (this.valueConTrol.yanXuanShangPin1688) {
          filter.push("jxhy");
        }
        if (this.valueConTrol.yanXuanGongChang1688) {
          filter.push("certifiedFactory");
        }
        if (this.valueConTrol.dianPuPinFen) {
          filter.push(this.valueConTrol.dianPuPinFen);
        }
        if (this.valueConTrol.qiTianWuLiYou) {
          filter.push("noReason7DReturn");
        }
        if (this.valueConTrol.yiJinaQiFa) {
          filter.push("isOnePsale");
        }

        let snidStr = [];
        Object.keys(this.valueConTrol.snId).forEach((key) => {
          if (this.valueConTrol.snId[key]) {
            snidStr.push(key + ":" + this.valueConTrol.snId[key]);
          }
        });
        snidStr = snidStr.join(";");
        searchData = {
          filter: filter.join(","),
          sort: this.valueConTrol.sort,
          priceStart: this.valueConTrol.priceStart,
          priceEnd: this.valueConTrol.priceEnd,
          snId: snidStr,
          keyword: this.valueConTrol.keySearch,
          authentication: Number(this.valueConTrol.ce),
        };
      }
      this.$emit("filterData", searchData);
    },

    find_owned_snId(name, v) {
      // console.log(this.valueConTrol.snId);
      if (
        this.valueConTrol.snId[name] != undefined &&
        this.valueConTrol.snId[name] == v
      ) {
        return true;
      } else {
        return false;
      }
    },
    chiose_snId(rowItem, valItem) {
      if (
        this.valueConTrol.snId[rowItem.id] &&
        this.valueConTrol.snId[rowItem.id] == valItem.id
      ) {
        this.valueConTrol.snId[rowItem.id] = "";
      } else {
        this.valueConTrol.snId[rowItem.id] = valItem.id;
      }
      this.$forceUpdate();
      this.confirmChuanshen();
      // console.log(this.valueConTrol.snId);
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.dynamicFiltering {
  width: 1440px;
  background-color: white;
  border-radius: 8px;
  padding: 24px 32px;
  margin: 0 auto;
  margin-bottom: 42px;
  .row {
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #dfdfdf;
    font-size: 16px;
    font-weight: 600;
    color: black;
    &:last-child {
      border-bottom: none;
    }
    label {
      font-size: 16px;
    }
    .spanBox {
      margin-right: 20px;
      padding: 3px 10px;
      border-radius: 4px;
      cursor: pointer;
    }
    .spanBox.active {
      background-color: #1e2997;
      color: white;
    }
    .el-checkbox {
      margin-right: 40px;
    }
    .sortingOptions {
      display: flex;
      align-items: center;
      // color: #999;
      margin-right: 24px;
      cursor: pointer;
      span {
        margin-right: 5px;
      }
      i {
        font-size: 16px;
      }
      .sortIcon {
        width: 24px;
        height: 24px;
        background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6684f47b49488.svg");
        background-size: 100% 100%;
      }
      .down {
        background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6684fe7856e26.png");
        transform: rotate(180deg); /* 旋转90度 */
      }
      .up {
        background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6684fe7856e26.png");
      }
      .switchBox {
        img {
          margin-right: 6px;
        }
        .el-switch {
          margin-left: 8px;
          /deep/.el-switch__label {
            span {
              font-size: 16px;
              color: #98a2b3;
              font-weight: 600;
            }
          }
          /deep/.el-switch__label.is-active {
            color: #1767d0;
          }
          /deep/.el-switch__label--left {
            margin-right: 8px;
          }
          /deep/.el-switch__label--right {
            margin-right: 8px;
          }
        }
      }
    }
    .sortingOptions.active {
      color: #1a73e8;
    }
    .searchInputBox {
      margin-right: 16px;
      position: relative;
      .el-input {
        width: 320px;
        /deep/.el-input__inner {
          height: 45px;
          font-size: 16px;
          border-radius: 8px;
          padding-left: 42px;
        }
        /deep/.el-input__suffix {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .searchIcon {
        position: absolute;
        left: 0px;
        top: 0;
        width: 42px;
        height: 45px;
        z-index: 1;
      }
      .inputIcon {
        background-color: white;
      }
      .segmentation {
        margin: 5px;
      }
    }
    .searchBtn {
      .el-button {
        padding: 0 16px;
        height: 45px;
        font-weight: 700;
        min-width: 100px;
      }
    }
  }
}
.el-checkbox {
  /deep/.el-checkbox__original {
    display: none;
  }
}
nav {
  height: 80px;
  // border-bottom: dashed 1px #dfdfdf;
  // 切换显示商品形式
  .crosswise {
    display: flex;
    margin-left: 20px;
    cursor: pointer;

    > div {
      height: 40px;

      &:first-child {
        margin-right: 20px;
      }
    }

    img {
      $searchImgSize: 40px;
      width: $searchImgSize;
      height: $searchImgSize;
    }
  }
}
nav.strSearch {
  height: 80px;
  // background-color: white;
  // box-shadow: 0px 0px 10px 0px rgba(61, 61, 61, 0.1);
  // border-radius: 4px;
  // padding: 0 20px 20px 20px;
  // width: $pageWidth;
  // margin: 30px auto 30px;
  position: relative;
  left: 0px;

  > div {
    display: flex;
  }

  .topCon {
    padding: 20px 0 0;
    display: flex;
    align-items: center;

    .keyword {
      // margin: 0 10px;
    }

    .query {
      color: $homePageSubjectColor;
    }

    .quantity {
      margin-left: auto;

      font {
        color: #ffa018;
      }
    }
  }
  /deep/.searchImg {
    border-radius: 10px;
    border: 1px solid rgba(#1e2997, 0.3);
    $imgSize: 50px;
    width: $imgSize;
    height: $imgSize;
    transition: 0.3s;
    text-align: center;

    i {
      font-size: $imgSize/4;
      line-height: $imgSize;
    }
  }

  .bottomCon {
    display: flex;
    height: 60px;
    align-items: center;

    .composeType {
      padding: 0 20px;
      width: 100%;
      height: 60px;
      background: #f0f0f0;
      border-radius: 10px;
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #000000;
      line-height: 24px;

      .title {
        margin-right: 80px;
        cursor: pointer;
      }

      > div {
        display: flex;
        align-items: center;
      }

      .salesVolume {
        margin-right: 80px;

        .el-select {
          width: 160px;
          height: 30px;
          border-radius: 6px;
        }

        /deep/.el-input__inner {
          width: 160px;
          height: 30px;
          border-radius: 6px;
          border: 1px solid rgba(#1e2997, 0.3);
        }

        /deep/.el-input__suffix {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .priceSection {
        display: flex;
        align-items: center;

        .fg {
          margin: 0 10px;
          width: 21px;
          height: 1px;
          background-color: black;
        }

        .inputBox {
          display: flex;
          align-items: center;
          width: 120px;
          height: 30px;
          border-radius: 6px;
          background-color: white;
          border: 1px solid rgba(#1e2997, 0.3);

          span {
            width: 10px;
            height: 20px;
            font-size: 14px;

            color: #000000;
            line-height: 20px;
            margin-left: 10px;
          }

          input {
            border: none;
            outline: none;
            width: 100%;
            padding: 0 3px;
          }
        }
      }
    }
  }
}
</style>
