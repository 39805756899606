<template>
  <div id="shopGoods">
    <HomePageTop></HomePageTop>
    <div class="nav flex acenter">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item v-if="$route.query.goodsName">
          <div class="navSpan">
            <span
              class="text-overflow-one"
              :title="$route.query.goodsName"
              @click="$router.go(-1)"
            >
              {{ $route.query.goodsName }}</span
            >
          </div>
          <!-- <span @click="$router.go(-1)" class="cur">
            {{ $route.query.goodsName }}</span
          > -->
        </el-breadcrumb-item>
        <el-breadcrumb-item class="shopNameItem">{{
          $route.query.shopName
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 店铺信息 -->
    <div class="width1440model shopInfoBox flex jsb">
      <div>
        <div class="flex acenter" style="margin-bottom:16px">
          <span
            class="shopNameBox"
            :title="$route.query.shopName"
            slot="reference"
            >{{ $route.query.shopName }}</span
          >
          <el-button
            v-if="!shopFavoriteStatus"
            type="primary"
            class="bgBlueBtn"
            @click="shopAddFavorite"
            >{{ $fanyi("收藏店铺") }}</el-button
          >
          <el-button
            type="primary"
            class="fontBlueBtn"
            v-else
            @click="favoriteShopDelete"
            >{{ $fanyi("取消追踪") }}</el-button
          >
        </div>
        <div class="flex acenter">
          <div class="rateBox">
            <div class="rateContainerHead flex acenter">
              <div class="text">{{ $fanyi("估价gobal") }}</div>
              <el-popover
                placement="bottom"
                popper-class="rateBoxPopover"
                width="309"
                trigger="hover"
                @show="hoverTip = true"
                @hide="hoverTip = false"
              >
                <div class="popoverRateBox">
                  <div class="rateContainer">
                    <div class="text">{{ $fanyi("质量评分") }}</div>
                    <el-rate
                      v-model="sellerDataInfo.offerExperienceScore"
                      disabled
                      text-color="#ff9900"
                    >
                    </el-rate>
                  </div>
                  <div class="rateContainer">
                    <div class="text">{{ $fanyi("物流评分") }}</div>
                    <el-rate
                      v-model="sellerDataInfo.logisticsExperienceScore"
                      disabled
                      text-color="#ff9900"
                    >
                    </el-rate>
                  </div>
                  <div class="rateContainer">
                    <div class="text">{{ $fanyi("问题解决评分") }}</div>
                    <el-rate
                      v-model="sellerDataInfo.disputeComplaintScore"
                      disabled
                      text-color="#ff9900"
                    >
                    </el-rate>
                  </div>
                  <div class="rateContainer">
                    <div class="text">{{ $fanyi("咨询评分") }}</div>
                    <el-rate
                      v-model="sellerDataInfo.consultingExperienceScore"
                      disabled
                      text-color="#ff9900"
                    >
                    </el-rate>
                  </div>
                </div>

                <div slot="reference" class="flex acenter">
                  <el-rate
                    v-model="sellerDataInfo.compositeServiceScore"
                    disabled
                    text-color="#ff9900"
                  >
                  </el-rate>
                  <img
                    :class="{ hoverIn: hoverTip }"
                    class="rateIcon"
                    src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6683797671ecb.svg"
                    alt=""
                  />
                </div>
              </el-popover>
            </div>
          </div>
          <div class="sellerTagsBar flex acenter">
            <!-- 实力商家 -->
            <div class="iconBox blue" v-if="haveShopsTag('powerful_merchants')">
              <img :src="require('@/assets/icon/goods1.svg')" alt="" />
              <span>{{ $fanyi("实力商家") }}</span>
            </div>
            <!-- 超级工厂 -->
            <div class="iconBox red" v-if="haveShopsTag('super_factory')">
              <img :src="require('@/assets/icon/goods2.svg')" alt="" />
              <span>{{ $fanyi("超级工厂") }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex acenter jsb" style="width:616px">
        <div
          class="shopTopGoodsItem"
          v-for="(hotitem, hotIndex) in hotGoods"
          :key="hotitem.goodsId"
          @click="
            $fun.routerToPage(
              '/ProductDetails?goods_id=' +
                hotitem.goodsId +
                '&LP=' +
                shopNameTrue,
              true
            )
          "
        >
          <div class="topGoodsImg">
            <img :src="hotitem.imgUrl" alt="" />
          </div>
          <div class="topTip">TOP {{ hotIndex + 1 }}</div>
        </div>
      </div>
    </div>

    <!-- 商品筛选 -->
    <keywordSearch @filterData="filterData" />

    <h1 class="bodyTitle">{{ $fanyi("商品推荐") }}</h1>
    <!-- 店铺商品页 -->
    <div class="SearchConterCrosswise" v-if="lists.length != 0">
      <router-link
        class="SearchConterCrosswiseCon"
        v-for="(item, index) in lists"
        :key="index"
        :to="'/ProductDetails?goods_id=' + item.goodsId + '&LP=' + shopNameTrue"
        target="_blank"
      >
        <!-- 放图片的div -->
        <div class="SearchConterCrosswiseImg">
          <!-- <img class="goodsImg" :src="item.imgUrl" /> -->
          <el-image :src="item.imgUrl" class="goodsImg">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
            <div slot="placeholder">
              <i class="el-icon-loading"></i>
            </div>
          </el-image>
          <!-- <loadingImg :src="item.imgUrl" /> -->
        </div>
        <!-- 显示其他信息的div -->
        <div class="rests">
          <p class="goodsTitle" :title="item.titleT">{{ item.titleT }}</p>
          <p class="flex acenter">
            <span
              >{{
                $fun.EURNumSegmentation(
                  $fun.ceil(item.goodsPrice * $store.state.exchangeRate)
                )
              }}€</span
            >{{ $fun.RMBNumSegmentation(item.goodsPrice) }}￥
          </p>
          <div class="totalTagBox">
            Ventas mensuales: {{ item.monthSold }} artículos
          </div>
          <div class="totalTagBox">
            Tasa de recompra: {{ item.repurchaseRate }}%
          </div>
        </div>
      </router-link>
    </div>
    <div class="loadingProBox" v-if="!finished">
      <i class="el-icon-loading"></i>
      <span>{{ $fanyi("加载中") }}</span>
    </div>
    <div class="donNotHaveGoods" v-loading="loading" v-if="goodsListIsNull">
      {{ txt }}
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import keywordSearch from "./components/model-keywordSearch.vue";
import Foot from "../../components/foot/Foot.vue";
import HomePageTop from "../../components/head/HomePageTop.vue";
export default {
  data() {
    return {
      hotGoods: [],
      form: {
        page: 0,
        pageSize: 50,
      },
      total: 0,
      keyWord: "",
      lists: [],
      txt: "",
      loading: true,
      timeout: false,
      finished: false,
      goodsListIsNull: false,
      shopFavoriteStatus: false,

      shopNameTrue: this.$route.query.shopName,
      wangwang: "",

      value1: 4,
      fiData: {},

      offerIdentities: [],
      sellerDataInfo: {},

      hoverTip: false, //控制星级评分箭头转动
    };
  },
  components: {
    HomePageTop,
    Foot,
    keywordSearch,
  },
  computed: {},
  created() {
    this.getData();
    this.getHotGoods();
    this.favoriteShopCheckIn();
  },
  watch: {
    $route: {
      handler(route) {
        this.finished = false;
      },
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scrollGetGoods);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollGetGoods);
  },
  methods: {
    // 判断显示商家标签
    haveShopsTag(in_name) {
      let offerIdentities = this.offerIdentities;
      if (!offerIdentities) return false;
      if (offerIdentities.find((ii) => ii == in_name)) return true;
      return false;
    },
    // 查看店铺是否已收藏
    favoriteShopCheckIn() {
      this.$api
        .favoriteShopCheckIn({
          shop_type: 1688,
          shop_id: JSON.stringify([this.$route.query.shopId]),
        })
        .then((res) => {
          if (res.code != 0) return false;
          this.shopFavoriteStatus = res.data[0].inFavorite;
        });
    },
    // 获取热门商品
    getHotGoods() {
      this.$api
        .sellerOfferList({
          sort: JSON.stringify({ monthSold: "desc" }),
          shopName: this.$route.query.shopName,
          shop_type: 1688,
          page: 1,
          pageSize: 5,
          shop_id: this.$route.query.shopId,
        })
        .then((res) => {
          ////console.log('事件名',res)
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          //接下来的操作
          this.hotGoods = res.data.goods;
          this.offerIdentities = res.data.offerIdentities;

          Object.keys(res.data.sellerDataInfo).forEach((key) => {
            res.data.sellerDataInfo[key] = Number(res.data.sellerDataInfo[key]);
          });
          this.sellerDataInfo = res.data.sellerDataInfo;
        });
    },
    // 筛选数据
    filterData(data) {
      this.form.page = 0;

      this.lists = [];
      this.fiData = data;

      this.finished = false;

      this.goodsListIsNull = false;

      this.timeout = false;
      this.getData();
    },
    // 店铺收藏移除
    favoriteShopDelete() {
      this.$api
        .favoriteShopDelete({
          shop_type: 1688,
          shop_name: this.shopNameTrue,
          shop_id: this.$route.query.shopId,
          wangwang: this.wangwang,
        })
        .then((res) => {
          if (res.code != 0) return false;
          this.shopFavoriteStatus = !this.shopFavoriteStatus;
          this.$forceUpdate();
        });
    },
    // 将店铺加入收藏夹
    shopAddFavorite() {
      if (!this.$store.state.userInfo) {
        return this.$store.commit(
          "getshowLoginDialog",
          !this.$store.state.showLoginDialog
        );
      }
      this.$api
        .favoriteShopAdd({
          shop_type: 1688,
          shop_name: this.shopNameTrue,
          shop_id: this.$route.query.shopId,
          wangwang: this.wangwang,
        })
        .then((res) => {
          if (res.code != 0) return false;
          this.shopFavoriteStatus = !this.shopFavoriteStatus;
          this.$forceUpdate();
        });
    },

    // // 页面滑动事件
    scrollGetGoods() {
      let dd = document.documentElement || document.body;
      if (dd.offsetHeight <= dd.scrollTop + 2000) {
        this.getData();
      }
    },

    // 获取列表数据
    getData() {
      if (!this.timeout) {
        this.timeout = true;
        if (this.finished) {
          return (this.timeout = false);
        }
        this.form.page++;

        this.form.keyWord = this.$fanyi("衣服");
        this.form.shop_type = 1688;

        let searchData = {
          shopName: this.$route.query.shopName,
          shop_type: 1688,
          page: this.form.page,
          pageSize: this.form.pageSize,
          shop_id: this.$route.query.shopId,
        };

        Object.keys(this.fiData).forEach((key) => {
          searchData[key] = this.fiData[key];
        });

        // 获取店铺商品数据
        this.$api
          .sellerOfferList(searchData)
          .then((res) => {
            this.loading = false;
            this.timeout = false;
            if (res.code != 0) {
              if (this.form.page == 1 && !this.lists.length) {
                this.goodsListIsNull = true;
                this.txt = this.$fanyi("此店铺暂无商品");
              }
              this.finished = true;
              return;
            }
            this.wangwang = res.data.shop_name;
            let resList = res.data.goods || res.data;

            if (resList && resList.length) {
              this.lists = [...this.lists, ...resList];
            }

            if (!resList || resList.length < this.form.pageSize) {
              if (
                this.form.page == 1 &&
                !(this.lists.length + resList.length)
              ) {
                this.goodsListIsNull = true;
                this.txt = this.$fanyi("此店铺暂无商品");
              }
              this.finished = true;
            }

            if (this.form.page == 1)
              document.body.scrollTop = document.documentElement.scrollTop = 0;

            this.$forceUpdate();
          })
          .catch((err) => {});
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#shopGoods {
  @import "../../css/mixin";
  width: 100%;
  background-color: #fafafa;
  .nav {
    width: 1440px;
    height: 58px;
    margin: 0 auto 27px;
    .el-breadcrumb__item {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
    .shopNameItem {
      /deep/.el-breadcrumb__inner {
        color: var(--Rakumart-500, #1a73e8);
        font-weight: 700;
      }
    }
  }
  .navSpan {
    display: inline-flex;
    max-width: 500px;
  }

  .width1440model {
    width: 1440px;
    background-color: white;
    border-radius: 8px;
    padding: 24px 32px;
    margin: 0 auto;
  }
  .shopInfoBox {
    margin-bottom: 16px;

    .shopNameBox {
      @extend .text-overflow-one;
      display: block;
      max-width: 500px;
      color: var(--Gray-700, #344054);
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      margin-right: 16px;
    }

    .rateBox {
      .rateContainerHead {
        .text {
          margin-right: 15px;
        }
        .rateIcon {
          transition: 0.3s;
          rotate: 180deg;
        }
        .rateIcon.hoverIn {
          rotate: 0deg;
        }
      }
    }

    .shopTopGoodsItem {
      width: 104px;
      border-radius: 16px;
      overflow: hidden;
      cursor: pointer;
      height: 104px;
      position: relative;
      transition: 0.3s;
      .topGoodsImg {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        background: #d9d9d9;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .topTip {
        transition: 0.3s;
        border-radius: 8px;
        background: var(--Rakumart-500, #1a73e8);
        width: 66px;
        height: 18px;
        color: var(--Base-White, #fff);
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        position: absolute;
        bottom: 14px;
        left: 50%;
        transform: translateX(-50%);
        margin: 0 auto;
      }
    }
    .shopTopGoodsItem:hover {
      transform: translate(0, -5px);
      box-shadow: 0px 10px 20px #d1d1d1;
      .topTip {
        bottom: 20px;
      }
    }
  }

  .iconBox {
    display: flex;
    align-items: center;
    padding: 2px 6px;
    border: solid 1px;
    border-radius: 6px;
    margin-right: 16px;
    img {
      width: 12px;
      margin-right: 4px;
    }
    span {
      font-size: 12px;
      font-weight: 600;
      line-height: 22px;
    }
  }
  .iconBox.red {
    color: #b93815;
    border-color: #f9dbaf;
    background: #fef6ee;
  }
  .iconBox.blue {
    color: #5925dc;
    border-color: #5925dc;
    background: #f4f3ff;
  }
  .sellerTagsBar {
    margin-left: 16px;
  }

  .bodyTitle {
    width: $pageWidth;
    margin: 0 auto 20px;
    font-size: 24px;
    color: #000000;
    line-height: 36px;

    margin-bottom: 20px;
  }
  .SearchConterCrosswise {
    width: $pageWidth;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 25px;
    min-height: 500px;

    .SearchConterCrosswiseCon {
      margin-right: 20px;
      margin-bottom: 15px;
      position: relative;
      transition: 0.3s;
      background-color: white;
      width: 264px;
      height: 373px;
      border: solid 1px #e8e8e8;
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);

      border-radius: 16px;
      overflow: hidden;
      cursor: pointer;
      &:hover {
        transform: translate(0, -5px);
        box-shadow: 0px 10px 20px #d1d1d1;

        .similarProducts {
          opacity: 1;
        }
      }

      &:nth-child(5n) {
        margin-right: 0px;
      }

      .SearchConterCrosswiseImg {
        height: 214px;

        overflow: hidden;
        height: 214px;
        position: relative;

        > .goodsImg {
          background-color: transparent;
        }

        .el-image {
          transition: 0.3s;
          width: 260px;
          height: 260px;
        }
      }

      .rests {
        box-sizing: border-box;
        padding: 16px 16px 24px;

        p {
          font-size: 12px;

          font-weight: 400;
          color: #000000;

          &.goodsTitle {
            @extend .text-overflow-one;
            height: 23px;
            font-size: 14px;
            font-weight: 600;
            color: #475467;
          }

          &:nth-child(2) {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 16px;
            color: #475467;
            > span {
              line-height: 24px;
              color: #1a73e8;
              font-weight: 700;
              margin-right: 8px;
            }
          }

          .collect {
            display: flex;
            align-items: center;

            img {
              width: 20px;
              height: 20px;
              margin-right: 10px;
            }

            span {
              font-size: 14px;

              color: #888888;
              line-height: 20px;
            }
          }
        }
        .totalTagBox {
          @extend .text-overflow-one;
          border-radius: 6px;
          border: 1px solid var(--Blue-200, #b2ddff);
          background: var(--Blue-50, #eff8ff);
          font-size: 13px;
          color: #1a73e8;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 8px;
          padding-left: 4px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .loadingProBox {
    font-size: 20px;
    color: #888888;
    text-align: center;
    padding-bottom: 20px;
  }

  .donNotHaveGoods {
    width: $pageWidth;
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 230px;
    font-size: 35px;
    color: #888888;
  }
}

/deep/.el-image.goodsImg {
  $imgSize: 260px;
  width: $imgSize;
  height: $imgSize;
  transition: 0.3s;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: $imgSize/4;
    line-height: $imgSize;
  }
  &:hover {
  }
}

/deep/.el-rate__icon {
  font-size: 24px;
  margin: -2px -3px;
  margin-right: 4px;
}

.popoverRateBox {
  padding: 12px;
  .rateContainer {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    .text {
      color: var(--Gray-700, #344054);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      display: flex;
      align-items: center;
      height: 20px;
    }
  }
}
</style>
